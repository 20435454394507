// REACT
import React from "react"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"
import { RentalsBlurb } from "../components/rentalsBlurb"

// COMPONENT FUNCTION
const RentalsAlcarPowerboatPage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      rentalsAlcarPowerboat01: file(
        relativePath: { eq: "rentals-alcar-powerboat-01.jpg" }
      ) {
        ...BsmFluidImage
      }
    }
  `)

  // COMPONENT
  return (
    <Layout title="Rentals - Alcar Powerboat">
      <PageTitle title="ALCAR POWERBOAT RENTAL" />

      <ImageBlock>
        <ImageWithCaption
          fluid={data.rentalsAlcarPowerboat01.childImageSharp.fluid}
          caption="Bay Sails 19' Alcar rental boat at the dock"
          displayCaption
          maxWidth={480}
        />
      </ImageBlock>

      <RentalsBlurb />
    </Layout>
  )
}

export default RentalsAlcarPowerboatPage
